import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BookmarkedBoardName from "../Board/BookmarkedBoardName";
import { useAppSelector } from "../../constants/types";
import MobileAllBoardName from "../Board/MobileAllBoardName";

function MobileBoardNavBar() {
  const [isBoardNameOpen, setIsBoardNameOpen] = useState<boolean>(false);
  const [isBookmarkedBoardNameOpen, setIsBookmarkedBoardNameOpen] =
    useState<boolean>(false);

  const boardNameRef = useRef<HTMLDivElement>(null);
  const hamburgerRef = useRef<HTMLDivElement>(null);
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const navigate = useNavigate();

  const goToHotDukPage = () => {
    navigate("/post/hotduk");
  };

  const goToRankDuckPage = () => {
    navigate("/post/rankduk");
  };

  const goToTotalBoard = () => {
    navigate("/post/totalpost");
  };

  const goToVoteBoard = () => {
    navigate("/board/voteboard");
  };

  const toggleboardNameOpen = () => {
    setIsBoardNameOpen(!isBoardNameOpen);
    // setIsBookmarkedBoardNameOpen(false);
  };

  const toggleBookmarkedBoardNameOpen = () => {
    setIsBookmarkedBoardNameOpen(!isBookmarkedBoardNameOpen);
    setIsBoardNameOpen(false);
  };

  const handleClickOutside = (e: any) => {
    if (
      boardNameRef.current &&
      !boardNameRef.current.contains(e.target) &&
      hamburgerRef.current &&
      !hamburgerRef.current.contains(e.target)
    ) {
      setIsBoardNameOpen(false);
      // setIsBookmarkedBoardNameOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <>
      <div className="px-4">
        <div className=" grid grid-cols-2 gap-x-5 gap-y-3">
          <div className="grid">
            <div
              className="flex justify-center items-center text-textcolor text-sm font-normal py-2 border border-[#D9D9D9]"
              onClick={toggleboardNameOpen}
              ref={hamburgerRef}
            >
              게시판 목록
              <div className="flex justify-center items-center ml-2">
                <img
                  src="/assets/icon/arrow_down.svg"
                  alt="arrow_down"
                  className="w-2 h-2"
                />
              </div>
            </div>
          </div>
          <div className="grid">
            <div
              className="flex justify-center items-center text-textcolor text-sm font-normal py-2 border border-[#D9D9D9]"
              onClick={goToTotalBoard}
            >
              전체게시판
            </div>
          </div>
          <div className="grid items-center">
            <div
              className="flex items-center justify-center text-main text-sm font-semibold py-2 border border-[#D9D9D9]"
              onClick={goToHotDukPage}
            >
              <img
                src="/assets/icon/hotduk.svg"
                alt="hotduk"
                className="w-3 mr-[6px]"
              />
              오늘의 HOT덕
            </div>
          </div>
          <div className="grid items-center">
            <div
              className="flex items-center justify-center text-rankduk text-sm font-semibold py-2 border border-[#D9D9D9]"
              onClick={goToRankDuckPage}
            >
              <img
                src="/assets/icon/rankduk.svg"
                alt="rankduk"
                className="w-3 mr-[6px]"
              />
              오늘의 RANK덕
            </div>
          </div>
        </div>
        <div className="flex relative w-full ">
          {isBoardNameOpen && (
            <div
              className="absolute -top-10 bg-white shadow-md z-50"
              ref={boardNameRef}
            >
              <MobileAllBoardName toggleBoardName={toggleboardNameOpen} />
            </div>
          )}
        </div>
        <div className=" flex flex-row-reverse relative ">
          {isLogin ? (
            <>
              {isBookmarkedBoardNameOpen && (
                <div className="absolute right-16 top-full bg-white shadow-md p-2 z-50">
                  <BookmarkedBoardName />
                </div>
              )}
            </>
          ) : (
            <>
              {isBookmarkedBoardNameOpen && (
                <div className="absolute right-6 top-full bg-white shadow-md p-2 z-50">
                  <div className="flex justify-center text-navy text-xs font-semibold">
                    로그인 후 이용해주세요.
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default MobileBoardNavBar;

function PolicyPage() {
  return (
    <>
      <div className="my-5 p-4 max-w-2xl mx-auto whitespace-normal">
        <div className="text-2xl font-semibold mb-2">
          10duk 개인정보취급방침
        </div>
        <p className="mb-4">
          10duk 사이트(이하 10duk)는 회원의 개인정보를 중요시하며, "정보통신망
          이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다. "10duk"은
          개인정보취급방침을 통하여 회원이 제공하는 개인정보가 어떠한 용도와
          방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고
          있는지 알려드립니다. "10duk"은 개인정보취급방침을 개정하는 경우
          웹사이트 공지사항(또는 개별 공지)을 통하여 공지할 것입니다.
        </p>
        <div className="text-xl font-semibold mt-6 mb-2">
          제1조 수집하는 개인정보 항목
        </div>
        <p>
          "10duk"은 회원가입 시 회원이 설정한 소셜 로그인 방식, ID, 비밀번호
          이외에 어떤 개인정보도 수집하지 않습니다. 단, 사이트 내 접속 및 활동
          시 아래 정보가 자동으로 생성되어 수집될 수 있습니다.
        </p>
        <ul className="list-disc ml-5 my-2">
          <li>
            서비스 이용 기록, 접속 로그, 방문일시, 기기 정보, 쿠키, IP주소, 부정
            이용 기록
          </li>
        </ul>
        <div className="text-xl font-semibold mt-6 mb-2">
          제2조 개인정보의 수집 및 이용목적
        </div>
        <p>"10duk"은 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
        <ul className=" list-decimal ml-5 my-2">
          <li className="mb-2">
            회원제 서비스 이용에 따른 본인 확인, 개인 식별, 불량회원의 부정 이용
            방지와 비인가 사용 방지, 가입 의사 확인, 각종 민원처리, 다중 계정
            제한, 중복 가입 제한, 고지사항 전달
          </li>
          <li className="mb-2">
            마케팅 및 광고에 활용, 신규 서비스 개발 및 특화, 이벤트 등 광고성
            정보 전달, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재
          </li>
          <li className="mb-2">
            접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계
          </li>
        </ul>
        <div className="text-xl font-semibold mt-6 mb-2">
          제3조 개인정보의 보유 및 이용기간
        </div>
        <p>
          "10duk"은 개인정보의 수집 및 이용목적이 달성된 후나 회원탈퇴와 같은
          개인정보 수집 제공에 대한 동의 철회 시 해당 정보를 파기하는 등 필요한
          조치를 취합니다. 다만 내부 방침 및 관계법령에 따라 보관할 수 있으며,
          구체적인 개인정보의 보유 및 이용기간은 다음과 같습니다.
        </p>
        <div className="text-xl font-semibold mt-6 mb-2">
          제4조 개인정보의 파기절차 및 방법
        </div>
        <p>
          “10duk”은 원칙적으로, 개인정보 수집 및 이용목적이 달성된 후 또는
          회원이 탈퇴한 경우에는 해당 정보를 지체없이 파기합니다. 다만 아래와
          같이 필요에 따라 이용자로부터 회원가입시 동의를 얻은 경우 또는
          법령에서 일정 기간 정보 보관 의무를 부과하는 경우에는 해당 기간동안
          개인정보를 안전하게 보관합니다.
        </p>
        <p className="mt-4 mb-2">가. 10duk 내부 방침에 의한 정보보유 사유</p>

        <ul className="list-disc pl-5 mb-4">
          <li className="mb-2">
            회원이 탈퇴한 경우에도 10duk은 원활한 서비스의 제공 및 부정한
            서비스의 이용을 방지하기 위하여 아래와 같은 관련 정보를 보존 기간
            동안 보관합니다.
            <ul className=" list-decimal pl-5 mt-2">
              <li>부정/불량 이용자의 IP, 회원조치 이력</li>
              <ul className="list-disc pl-5 mt-2">
                <li>
                  보존 이유: 부정 및 불량 이용자의 서비스 이용 제한과 조치 이력
                  보관으로 악의적 이용의 재발 방지
                </li>

                <li>보존 기간: 탈퇴일로부터 5년</li>
              </ul>
            </ul>
          </li>
        </ul>

        <p className="mb-2">나. 관련 법령에 의한 정보보유 사유</p>
        <ul className=" list-disc pl-5 mt-2">
          <li>
            보존 항목: 서비스 이용 및 방문 기록, 접속 로그 기록, 접속IP 정보
          </li>
          <li>보존 근거 : 통신비밀보호법</li>
          <li>보존 기간: 3개월</li>
        </ul>
        <div className="text-xl font-semibold mt-6 mb-2">
          제5조 개인정보 제공
        </div>
        <p>
          "10duk"은 회원의 개인정보를 원칙적으로 외부에 제공하지 않으며,
          정보주체의 사전 동의 없이 본래의 범위를 초과하여 처리하거나 제3자에게
          제공하지 않습니다. 다만, 다른 법률에 특별한 규정이 있는 경우 또는
          범죄의 수사와 같이 개인정보보호법 제18조 제1항에 해당하는 경우는
          예외로 처리됩니다.
        </p>
        <div className="text-xl font-semibold mt-6 mb-2">
          제6조 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
        </div>
        <p>
          "10duk"은 회원의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
          운용합니다.
        </p>
        <p>
          쿠키는 웹사이트를 운영하는데 이용되는 서버가 회원의 브라우저에 보내는
          작은 정보이며 회원의 컴퓨터 하드디스크 및 보조기억장치에 저장되기도
          합니다. "10duk"은 다음과 같은 목적을 위해 쿠키를 사용합니다.
        </p>
        <ul className=" list-decimal pl-5 mt-2">
          <li>쿠키 등 사용 목적</li>
          <ul className=" list-disc pl-5 mt-2">
            <li>
              회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 회원의 취향과
              관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 횟수
              파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
            </li>
          </ul>
          <li className="mt-2">쿠키의 설치 및 운영 거부</li>
          <ul className=" list-disc pl-5 mt-2">
            <li>
              회원은 웹 브라우저 상단의 도구 {">"} 인터넷 옵션 {">"} 개인정보
              메뉴의 설정을 통해 쿠키를 거부할 수 있습니다.
            </li>
          </ul>
          <li className="mt-2">
            쿠키 설치를 거부할 경우 일부 서비스 이용에 어려움이 발생할 수
            있습니다.
          </li>
        </ul>
        <div className="text-xl font-semibold mt-6 mb-2">
          제7조 개인정보에 관한 민원서비스
        </div>
        <p>
          "10duk"은 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
          위하여 아래와 같이 개인정보관리책임자를 지정하고 있습니다.
        </p>
        <p className="my-2">개인정보 관리 책임자 : 10duk 운영자</p>
        <p>e-mail: 10duk.official@gmail.com</p>
        <p className="mt-2">
          기타 개인정보 침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
          문의하시기 바랍니다.
        </p>
        <ul className=" list-disc pl-5 mt-2">
          <li className="mb-2">
            개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)
          </li>
          <li className="mb-2">
            대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)
          </li>
          <li className="mb-2">
            경찰청 사이버안전국 ( https://cyberbureau.police.go.kr , 국번 없이
            182 )
          </li>
        </ul>
        <div className="text-xl font-semibold mt-6 mb-2">
          제 8조 개인정보취급방침의 변경
        </div>
        <p>
          10duk은 향후 개인정보취급방침을 개정하는 경우 그 개정사유 및
          적용일자를 명시하여 최소 7일 전부터 공지사항을 통해 게시할 것입니다.
          다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자
          권리의 중대한 변경이 발생할 때에는 최소 30일 전에 공지하며, 필요 시
          이용자의 동의를 다시 받을 수 있습니다.
        </p>
        <p className="my-6">
          (적용일) 본 개인정보취급방침은 2023년 10월 01일부터 적용됩니다.
        </p>
      </div>
    </>
  );
}

export default PolicyPage;

import LinkBtn from "../Common/LinkBtn";
import { useNavigate } from "react-router-dom";

function MainVoteBoard() {
  const navigate = useNavigate();

  const goToVoteBoard = () => {
    navigate("/board/voteboard");
  };

  return (
    <div
      className=" bg-vote_board flex justify-center items-center mt-5 py-5 rounded-xl hover:opacity-70 cursor-pointer"
      onClick={goToVoteBoard}
    >
      <p className="flex text-white text-xl font-bold mr-8">게시판 추가 투표</p>
      <LinkBtn
        text="바로가기"
        textStyle="text-white text-sm font-normal"
        padding="py-1 px-4"
        borderStyle="border border-white"
        btnFunc={goToVoteBoard}
        className=" hover:bg-opacity-50"
      />
    </div>
  );
}

export default MainVoteBoard;

import React from "react";
import { redirectGoogle } from "../../apis/user";

function GoogleBtn() {
  const handleGoogleLogin = () => {
    redirectGoogle();
  };

  return (
    <div className="flex justify-center">
      <div
        onClick={handleGoogleLogin}
        className="flex bg-[#FFFFFF] shadow rounded-md h-12 w-80  mx-auto cursor-pointer border border-black border-opacity-[8%]"
      >
        <img
          src="/assets/google_logo.svg"
          alt="google-logo"
          className="max-h-12"
        />

        <div className="flex mx-auto">
          <div className="flex text-black text-opacity-[54%] font-sans font-medium items-center text-[15px] pr-6">
            구글 로그인
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoogleBtn;

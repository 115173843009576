import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import BoardNavBar from "../NavBar/BoardNavBar";
import VoteBoardRemote from "../Common/VoteBoardRemote";
import CarouselPosts from "../Main/CarouselPosts";
import { useAppSelector } from "../../constants/types";
import NewPostsRemote from "../Common/NewPostsRemote";

function Layout() {
  const isAdminAdmin = useAppSelector((state) => state.auth.isAdminAdmin);
  const bgColor = isAdminAdmin ? "bg-[#D1E6FF]" : null;

  return (
    <>
      <div className={`flex flex-col min-h-[100vh] ${bgColor}`}>
        <NavBar />
        <div className="mt-1 w-2/3 mx-auto justify-center">
          <CarouselPosts />
        </div>
        <div className="mt-6 border-b border-header_line"></div>
        <BoardNavBar />
        <div className="flex min-h-[63vh]">
          <div className="w-3/12 px-10 justify-center min-h-[63vh]">
            <div className="sticky top-16 mt-5">
              <NewPostsRemote />
            </div>
          </div>
          <div className="w-2/3 px-2">
            <Outlet />
          </div>
          <div className="w-1/12 justify-center px-5 min-h-[63vh]">
            <div className="sticky top-28 mt-5">
              <VoteBoardRemote />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Layout;

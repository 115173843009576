import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainBoardsPreview from "../../components/Main/MainBoardsPreview";
import { getHotDuk, getMainPosts, getRankDuk } from "../../apis/post";
import { MainPost, Boards } from "../../constants/types";
import { getBoardName } from "../../apis/board";
import MobileMainBoardPreview from "./MobileMainBoardPreview";

function MobileMainContent() {
  const navigate = useNavigate();
  const [boards, setBoards] = useState<Boards[]>([]);
  const [mainPostList, setMainPostList] = useState<MainPost[]>([]);
  const [rankerPostList, setRankerPostList] = useState<MainPost[]>([]);
  const [popularPostList, setPopularPostList] = useState<MainPost[]>([]);

  const btnFuncHotduk = () => {
    navigate("/post/hotduk/1");
  };

  const btnFuncRankduk = () => {
    navigate("/post/rankduk/1");
  };

  useEffect(() => {
    getBoardName()
      .then((res) => {
        setBoards(res);
        res.forEach((board: Boards) => {
          handleGetMainPosts(board.boardNumber);
        });
      })
      .catch((err) => {});

    getHotDuk()
      .then((res) => {
        setPopularPostList(res);
      })
      .catch((err) => {});

    getRankDuk()
      .then((res) => {
        setRankerPostList(res);
      })
      .catch((err) => {});
  }, []);

  const handleGetMainPosts = (boardNumber: number) => {
    getMainPosts(boardNumber)
      .then((res) => {
        // boardNumber를 기반으로 mainPostList 필터링
        setMainPostList((prevMainPostList) => [...prevMainPostList, ...res]);
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className="px-4">
        <div className="mb-3 ">
          <MainBoardsPreview
            boardName="오늘의 HOT 덕"
            borderStyle="border border-[#E4E4E4] rounded"
            boardNameStyle="text-white text-xl font-bold"
            titleStyle="bg-special_duk rounded-t py-4"
            postList={popularPostList}
            btnTextStyle="text-white text-sm font-normal"
            btnBorderStyle="border border-white"
            btnFunc={btnFuncHotduk}
          />
        </div>
        <div className="mb-3">
          <MainBoardsPreview
            boardName="오늘의 RANK 덕"
            borderStyle="border border-[#E4E4E4] rounded"
            boardNameStyle="text-white text-xl font-bold"
            titleStyle="bg-special_duk rounded-t py-4"
            postList={rankerPostList}
            btnTextStyle="text-white text-sm font-normal"
            btnBorderStyle="border border-white"
            btnFunc={btnFuncRankduk}
          />
        </div>
        {boards.map((board) => (
          <div className="mb-3" key={board.boardNumber}>
            <MainBoardsPreview
              boardCreatedAt={board.createdAt}
              boardName={board.boardName}
              boardNumber={board.boardNumber}
              postList={mainPostList.filter(
                (post) => post.boardNumber === board.boardNumber
              )}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default MobileMainContent;

import React from "react";
import MobileBoardNavBar from "../../components/NavBar/MobileBoardNavBar";
import MainVoteBoard from "../../components/Main/MainVoteBoard";
import MobileMainVoteBoard from "../../components/Main/MobileMainVoteBoard";
import MobileMainContent from "../../components/Main/MobileMainContent";

function MobileMainPage() {
  return (
    <>
      <MobileBoardNavBar />
      <MobileMainVoteBoard />
      <div className="mt-4"></div>
      <MobileMainContent />
    </>
  );
}

export default MobileMainPage;

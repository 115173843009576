import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../constants/types";
import { getBoardName } from "../../apis/board";
import { deleteUser } from "../../apis/user";
import {
  updateAccessToken,
  updateAdminStatus,
  updateAdminAdminStatus,
  updateLoginStatus,
  updateUserId,
} from "../../store/authReducer";

interface UserInfoProps {
  userCreatedAt: string;
}

function formatDate(dateString: string): string {
  //todo date 로직 바꿔야할지도 모름
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options).replace(/\//g, ".");
}

function UserInfo({ userCreatedAt }: UserInfoProps) {
  const formattedDate = formatDate(userCreatedAt);
  const isRanker = useAppSelector((state) => state.auth.isRanker);
  const isAdmin = useAppSelector((state) => state.auth.isAdmin);
  const isAdminAdmin = useAppSelector((state) => state.auth.isAdminAdmin);

  const [rankerBoards, setRankerBoards] = useState<string[]>([]);

  useEffect(() => {
    if (Object.keys(isRanker).length > 0) {
      // Create an array of promises for each asynchronous call
      const rankerBoardPromises: Promise<string>[] = [];

      for (let boardNum in isRanker) {
        if (Object.prototype.hasOwnProperty.call(isRanker, boardNum)) {
          const value = isRanker[boardNum as keyof typeof isRanker];
          if (value) {
            // Push the promise into the array
            rankerBoardPromises.push(
              getBoardName(parseInt(boardNum, 10)).then((res) => res.boardName)
            );
          }
        }
      }

      // Wait for all promises to resolve
      Promise.all(rankerBoardPromises).then((resolvedBoards) => {
        setRankerBoards(resolvedBoards);
      });
    }
  }, [isRanker]);

  //회원 탈퇴
  const dispatch = useAppDispatch();
  const deleteAccount = () => {
    const deleteUserConfirm = window.confirm("정말로 탈퇴하시겠습니까?");
    if (deleteUserConfirm) {
      deleteUser()
        .then(() => {
          dispatch(updateLoginStatus(false));
          dispatch(updateAccessToken(""));
          dispatch(updateUserId(""));
          dispatch(updateAdminStatus(false));
          dispatch(updateAdminAdminStatus(false));
          alert("탈퇴되었습니다.");
          window.location.replace("/");
        })
        .catch((err) => { });
    } else {
      return;
    }
  };

  const handleAdminButton = () => {
    window.location.replace("/admin/page");
  };

  return (
    <>
      <div className="text-xl text-[#222222] font-semibold my-5">내 정보</div>
      <div className="w-4/5 flex bg-[#FAFAFA] text-textcolor text-base p-3 items-center justify-center">
        <img src="/assets/icon/profile.svg" alt="profile" className="w-16" />
        <div>
          <div className="pl-5 text-sm">가입일 : {formattedDate}</div>
          {rankerBoards.length > 0 ? (
            <div className="pt-2 pl-5 text-xs">
              {`당신은 ${rankerBoards
                .map((board) => `"${board}" 게시판`)
                .join(", ")} 랭커입니다`}
            </div>
          ) : (
            <div className="pt-2 pl-5 text-xs text-zinc-600">
              당신은 랭커가 아닙니다
            </div>
          )}
        </div>
      </div>
      {isAdmin && isAdminAdmin && (
        <button
          className="mt-2 py-1 px-2 bg-blue-400 text-white rounded hover:bg-blue-700"
          onClick={handleAdminButton}>
          관리자 설정
        </button>
      )}
      <div className=" text-xs opacity-5">
        <span onClick={deleteAccount}>탈퇴하기</span>
      </div>
    </>
  );
}

export default UserInfo;

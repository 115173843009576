import React from "react";
import MobileTotalPost from "../../components/Posts/MobileTotalPost";

function MobileTotalPostPage() {
  return (
    <>
      <div className="flex my-5">
        <div className="pl-4 text-textcolor text-2xl font-bold">전체게시판</div>
      </div>
      <div>
        <MobileTotalPost />
      </div>
    </>
  );
}

export default MobileTotalPostPage;

import React, { useEffect, useState } from "react";
import { getBoardName } from "../../apis/board";
import { Boards } from "../../constants/types";

const parseDate = (dateString: any) => {
  return new Date(dateString);
};

interface MobileAllBoardNameProps {
  toggleBoardName: () => void;
}
function MobileAllBoardName({ toggleBoardName }: MobileAllBoardNameProps) {
  const [boards, setBoards] = useState<Boards[]>([]);

  useEffect(() => {
    getBoardName()
      .then((res) => {
        setBoards(res);
      })
      .catch((err) => {});
  }, []);

  const goToBoard = (boardNumber: number) => {
    window.location.href = `/board/${boardNumber}`;
  };

  const goToTotalBoard = () => {
    window.location.href = `/post/totalpost`;
  };

  const currentDate = new Date();

  const isNewBoard = (createdAt: string): boolean => {
    const boardDate = parseDate(createdAt);
    const diffInMs = currentDate.getTime() - boardDate.getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return diffInDays < 7;
  };

  return (
    <div className="flex ml-5 text-[#6B6B6B] ">
      <div className="grid grid-flow-row gap-4 py-3 pr-4">
        <div className="flex p-1 text-sm font-medium" onClick={goToTotalBoard}>
          전체 게시판
        </div>
        {boards.map((board: any) => (
          <div
            key={board.boardNumber}
            className="flex p-1 text-sm font-medium"
            onClick={() => goToBoard(board.boardNumber)}
          >
            {isNewBoard(board.createdAt) && (
              <div className="flex justify-center items-center mr-1">
                <div className="text-hotduk font-semibold items-start">N</div>
              </div>
            )}
            {board.boardName.length > 8
              ? board.boardName.slice(0, 8) + "..."
              : board.boardName}

            {board.todayPostCount > 0 && (
              <div className="flex justify-center items-center ml-1">
                <div className="text-rankduk font-semibold items-start">
                  +{board.todayPostCount}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div
        className="flex ml-5 p-3 justify-end cursor-pointer hover:opacity-50"
        onClick={toggleBoardName}
      >
        <img src="/assets/icon/close.svg" alt="close" className="w-3 h-3" />
      </div>
    </div>
  );
}

export default MobileAllBoardName;

import { MainPost } from "../../constants/types";
import { formatAgo } from "../../util/date";
import { useNavigate } from "react-router-dom";
import LinkBtn from "../Common/LinkBtn";

const parseDate = (dateString: any) => {
  return new Date(dateString);
};

interface MainBoardsPreviewProps {
  boardName: string;
  boardCreatedAt?: string;
  boardNumber?: number;
  boardNameStyle?: string;
  titleStyle?: string;
  postList: MainPost[];
  borderStyle?: string;
  btnText?: string;
  btnTextStyle?: string;
  btnFunc?: () => void;
  btnBorderStyle?: string;
  btnPadding?: string;
  btnClassName?: string;
}

function MainBoardsPreview({
  boardName,
  boardCreatedAt,
  boardNumber,
  boardNameStyle,
  titleStyle,
  postList,
  borderStyle,
  btnText,
  btnTextStyle,
  btnFunc,
  btnBorderStyle,
  btnPadding,
  btnClassName,
}: MainBoardsPreviewProps) {
  const navigate = useNavigate();
  const border = borderStyle
    ? borderStyle
    : "border border-[#E4E4E4] rounded-lg";
  const boardNameStyleDefault = boardNameStyle
    ? boardNameStyle
    : "text-black text-xl font-bold";
  const boardCreatedAtDefault = boardCreatedAt ? boardCreatedAt : "";
  const boardNumberDefault = boardNumber ? boardNumber : 0;
  const titleStyleDefault = titleStyle
    ? titleStyle
    : "bg-[#F8F8F8] rounded-t-lg";
  const btnTextDefault = btnText ? btnText : "바로가기";
  const btnTextStyleDefault = btnTextStyle
    ? btnTextStyle
    : "text-[#6B6B6B] text-sm font-normal";
  const btnBorderStyleDefault = btnBorderStyle
    ? btnBorderStyle
    : "border border-[#CDCDCD]";

  const btnPaddingDefault = btnPadding ? btnPadding : "py-1 px-3";
  const btnFuncDefault = btnFunc
    ? btnFunc
    : () => {
        navigate(`/board/${boardNumberDefault}`);
        window.scrollTo(0, 0);
      };

  const btnClassNameDefault = btnClassName ? btnClassName : "hover:bg-gray";

  const posts = postList.length > 5 ? postList.slice(0, 5) : postList;

  const handlePostClick = (postBoardNumber: number, postId: string) => {
    navigate(`/post/${postBoardNumber}/${postId}`);
    window.scrollTo(0, 0);
  };

  const currentDate = new Date();

  const isNewPost = (createdAt: string): boolean => {
    const postDate = parseDate(createdAt);
    const diffInMs = currentDate.getTime() - postDate.getTime();
    const diffInHours = diffInMs / (1000 * 60 * 180);
    return diffInHours <= 1;
  };

  const isNewBoard = (createdAt: string): boolean => {
    const boardDate = parseDate(createdAt);
    const diffInMs = currentDate.getTime() - boardDate.getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return diffInDays < 7;
  };

  if (!Array.isArray(posts)) return null;

  return (
    <div className={`w-full ${border}`}>
      <div
        className={`flex justify-between items-center py-3 px-5 cursor-pointer hover:bg-opacity-70 ${titleStyleDefault}`}
        onClick={btnFuncDefault}
      >
        <p className={`flex ${boardNameStyleDefault}`}>
          {boardName}
          {isNewBoard(boardCreatedAtDefault) && (
            <span className="text-hotduk text-[10px] font-semibold text-start ml-1">
              NEW
            </span>
          )}
        </p>
        <LinkBtn
          text={btnTextDefault}
          textStyle={btnTextStyleDefault}
          padding={btnPaddingDefault}
          borderStyle={btnBorderStyleDefault}
          btnFunc={btnFuncDefault}
          className={btnClassNameDefault}
        />
      </div>
      <div className="py-5">
        {posts.map((post) => (
          <div
            key={post.id}
            className="flex justify-between items-center py-1 px-5 cursor-pointer hover:bg-[#F8F8F8]"
            onClick={() => handlePostClick(post.boardNumber, post.id)}
          >
            <div className="text-sm text-textcolor font-normal flex">
              {isNewPost(post.createdAt) && (
                <span className="text-hotduk text-[8px] font-semibold items-start mr-1">
                  NEW
                </span>
              )}
              {post.title.length > 20
                ? post.title.slice(0, 20) + "..."
                : post.title}

              {post.mainImage && (
                <img
                  src="/assets/icon/10duk_photo.svg"
                  alt="image"
                  className="ml-1 w-3"
                />
              )}

              {post.commentCount > 0 && (
                <div className="text-hotduk text-xs pt-[2px] font-semibold items-end ml-1">
                  {post.commentCount}
                </div>
              )}
            </div>
            <div className="text-sm text-[#6B6B6B] font-normal">
              {formatAgo(post.createdAt, "ko")}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MainBoardsPreview;

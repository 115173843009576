import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../constants/types';
import { logout } from '../../apis/user';
import {
  updateAccessToken,
  updateAdminStatus,
  updateAdminAdminStatus,
  updateLoginStatus,
  updateUserId,
  updateLoginRedirectUrl,
  updateBlockStatus,
} from '../../store/authReducer';
import CarouselPosts from '../Main/CarouselPosts';

function NavBar() {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchType, setSearchType] = useState<string>('all');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLogin = useAppSelector((state) => state.auth.isLogin);

  const goToMain = () => {
    navigate(`/`);
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    if (!searchQuery || searchQuery.trim() === '') {
      alert('검색어를 입력해주세요.');
      return;
    }
    navigate(`/search?type=${searchType}&query=${searchQuery}`);
    setSearchQuery('');
    setSearchType('all');
  };

  const goToLogin = () => {
    const currentUrl = window.location.pathname;
    dispatch(updateLoginRedirectUrl(currentUrl));
    navigate('/login');
  };

  const handleLogout = async () => {
    try {
      await logout();
      dispatch(updateLoginStatus(false));
      dispatch(updateAccessToken(''));
      dispatch(updateUserId(''));
      dispatch(updateAdminStatus(false));
      dispatch(updateAdminAdminStatus(false));
      dispatch(updateBlockStatus(false));
      //todo blocked 삭제
      alert('로그아웃 되었습니다');
    } catch (error) {}
  };

  const goToProfile = () => {
    navigate('/profile');
  };

  return (
    <div className='w-full flex pt-6 px-6 whitespace-nowrap'>
      <div className='w-2/3 flex mx-auto'>
        <div className='flex w-1/6 justify-start items-center'>
          <img
            src='/assets/icon/10duk_newlogo_fin_02.svg'
            alt='로고이미지'
            onClick={goToMain}
            className='cursor-pointer'
            id='logo'
          />
        </div>
        <div className='w-full flex justify-center'>
          <form
            className='w-2/3 px-2 py-1 flex border-[1.5px] rounded-3xl border-navbar'
            onSubmit={handleSearch}
          >
            <select
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
              className='w-1/5 flex justify-center items-center mx-2 text-[#333333] text-xs outline-none'
            >
              <option value='all'>전체</option>
              <option value='title'>제목</option>
              <option value='text'>내용</option>
            </select>
            <input
              type='text'
              placeholder='검색어를 입력하세요.'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className='w-3/5 outline-none mx-2 text-xs'
            />

            <button className='w-1/5 px-3 flex justify-end items-center'>
              <img
                src='/assets/icon/search.svg'
                alt='search'
                id='search'
                width={18}
              />
            </button>
          </form>
          {/* <div className="w-2/3 px-2 py-1 flex absolute top-0 z-10">
            <CarouselPosts />
          </div> */}
        </div>
        <div className='flex items-center'>
          {isLogin ? (
            <div className='flex items-center py-2 justify-end'>
              <button onClick={handleLogout} className='flex items-center'>
                <img
                  className='flex px-2'
                  src='/assets/icon/login.svg'
                  alt='login'
                  id='login'
                />
                <div className='flex text-xs text-[#6B6B6B] px-2'>로그아웃</div>
              </button>
              <div className='flex items-center ml-4 px-1 py-3 border-r-[1.5px] border-[#E1E3E3]'></div>
              <button onClick={goToProfile} className='flex items-center'>
                <img
                  className='flex pl-4 pr-2'
                  src='/assets/icon/signup.svg'
                  alt='signup'
                  id='signup'
                />
                <div className='flex text-xs text-[#6B6B6B] px-1'>프로필</div>
              </button>
            </div>
          ) : (
            <div className='flex ml-auto w-1/5 items-center py-2 justify-end px-3'>
              <button onClick={goToLogin} className='flex items-center'>
                <img
                  className='flex px-2'
                  src='/assets/icon/login.svg'
                  alt='login'
                  id='login'
                />
                <div className='flex text-xs text-[#6B6B6B] px-2'>로그인</div>
              </button>
              <div className='flex items-center ml-4 px-1 py-3 border-r-[1.5px] border-[#E1E3E3]'></div>
              <button onClick={goToLogin} className='flex items-center'>
                <img
                  className='flex pl-4 pr-2'
                  src='/assets/icon/signup.svg'
                  alt='signup'
                  id='signup'
                />
                <div className='flex text-xs text-[#6B6B6B] px-1'>회원가입</div>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NavBar;

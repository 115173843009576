import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TwitterReferrerState {
  isTwitterReferrer: boolean;
  redirectUrl: string;
}

const initialState: TwitterReferrerState = {
  isTwitterReferrer: false,
  redirectUrl: "",
};

const twitterReferrerSlice = createSlice({
  name: "twitterReferrer",
  initialState,
  reducers: {
    setIsTwitterReferrer: (state, action: PayloadAction<boolean>) => {
      state.isTwitterReferrer = action.payload;
    },
    setRedirectUrl: (state, action: PayloadAction<string>) => {
      state.redirectUrl = action.payload;
    },
    clearRedirectUrl: (state) => {
      state.redirectUrl = "";
    },
  },
});

export const { setIsTwitterReferrer, setRedirectUrl, clearRedirectUrl } =
  twitterReferrerSlice.actions;

export default twitterReferrerSlice.reducer;

import LoginMain from "../../components/Login/LoginMain";

function LoginPage() {
  return (
    <>
      <div className="w-full flex-col justify-center items-center mt-24">
        <LoginMain />
      </div>
    </>
  );
}

export default LoginPage;

import React from "react";
import LocalLogin from "../../components/Login/LocalLogin";

function LocalLoginPage() {
  return (
    <div>
      LocalLoginPage
      <LocalLogin />
    </div>
  );
}

export default LocalLoginPage;

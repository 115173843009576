import React from "react";

interface LinkBtnProps {
  text: string;
  textStyle: string;
  btnFunc?: () => void;
  borderStyle?: string;
  padding?: string;
  className?: string;
}

/**
 *
 * @param text 버튼 텍스트
 * @param btnFunc 클릭시 실행할 함수
 * @param borderStyle 버튼 테두리 스타일
 * @param padding 버튼 패딩
 * @param className 기타 클래스
 */

function LinkBtn({
  textStyle,
  text,
  btnFunc,
  padding,
  borderStyle,
  className,
}: LinkBtnProps) {
  return (
    <button
      type="button"
      onClick={btnFunc}
      className={`${textStyle} ${padding} ${borderStyle} ${className}`}
    >
      {text}
    </button>
  );
}

export default LinkBtn;

function TermsOfUsePage() {
  return (
    <>
      <div className="my-5 p-4 max-w-2xl mx-auto whitespace-normal">
        <div className="text-2xl font-semibold mb-2">10duk 약관</div>
        <div className="text-xl font-semibold mt-6 mb-2">제 1조 목적</div>
        <ul className="list-decimal ml-5 my-2">
          <li>
            10duk(www.10duk.com) 서비스 약관(이하 “본 약관”이라고 합니다.)은
            회원이 10duk에서 제공하는 인터넷 관련 서비스를 이용함에 있어 회원과
            “10duk”의 권리/의무 및 책임사항을 규정함을 목적으로 합니다.
          </li>
        </ul>
        <div className="text-xl font-semibold mt-6 mb-2">
          제 2조 용어의 정의
        </div>
        <ul className=" list-decimal ml-5 my-2">
          <li className="mb-2">
            회원 : 이 약관에 동의하고 가입신청을 통해 서비스를 이용하는 이용자
          </li>
          <li className="mb-2">
            기타 약관에서 정하지 아니한 용어는 관계 법령 및 일반 관례에
            따릅니다.
          </li>
        </ul>
        <div className="text-xl font-semibold mt-6 mb-2">
          제 3조 약관의 효력 및 변경
        </div>
        <ul className=" list-decimal ml-5 my-2">
          <li className="mb-2">
            이 약관은 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력을
            발생합니다.
          </li>
          <li className="mb-2">
            이 약관의 내용은 회원이 이에 동의하여 서비스에 가입함으로써 효력이
            발생합니다.
          </li>
          <li className="mb-2">
            약관에 대한 동의는 이용 신청시 화면 상의 ‘위 약관에
            동의하시겠습니까?’ 항목에 체크를 함으로써 이루어지는 것으로
            간주합니다.
          </li>
          <li className="mb-2">
            회사는 필요한 사유가 발생할 경우 관련 법령에 위배되지 않는 범위
            안에서 약관을 개정할 수 있습니다. 회사가 약관을 변경한 경우에 변경된
            약관의 내용과 시행일을 정하여, 그 시행일로부터 7일전 홈페이지에
            온라인으로 공시합니다. 다만, 회원에게 불리하게 약관 내용을 변경하는
            경우에는 시행일로부터 30일전 홈페이지에 온라인으로 공시하는 방법으로
            회원에게 고지합니다. 변경된 약관은 공시하거나 고지한 시행일로부터
            효력이 발생합니다.
          </li>
          <li className="mb-2">
            회원은 변경된 약관에 대하여 동의하지 않을 경우 서비스 이용을
            중단하고 이용 계약을 해지(탈퇴)할 수 있으며, 만약 변경된 약관의 적용
            이후에도 서비스를 계속 이용하는 경우에는 약관의 변경 사항에 동의한
            것으로 간주합니다.
          </li>
        </ul>
        <div className="text-xl font-semibold mt-6 mb-2">
          제 4조 회원가입과 제한
        </div>
        <ul className=" list-decimal ml-5 my-2">
          <li className="mb-2">
            회원으로 가입하고자 하는 이용자는 “10duk”에서 정한 소정의 절차에
            따라 “회원 가입” 또는 이와 유사한 버튼을 누르는 것으로 회원 가입을
            신청합니다.
          </li>
          <li className="mb-2">
            “10duk”은 제 1항과 같이 회원으로 가입할 것을 신청한 이용자가 다음 각
            호에 해당하지 않는 한, 신청한 자를 회원으로 등록합니다.
            <ul className="my-2">
              <li className="mb-2">
                가. 법률 또는 약관을 위반하여 제재받은 사용자가 회원가입을 다시
                신청하는 경우
              </li>
              <li className="mb-2">
                나. 기타 회원으로 등록하는 것이 "10duk"의 서비스 운영에 현저히
                지장이 있다고 판단되는 경우
              </li>
            </ul>
          </li>
        </ul>

        <div className="text-xl font-semibold mt-6 mb-2">
          제 5조 회원 탈퇴 및 자격 상실 등
        </div>
        <ul className=" list-decimal ml-5 my-2">
          <li className="mb-2">
            회원은 "10duk"에 언제든지 자신의 회원 등록을 말소해 줄 것(회원
            탈퇴)을 요청할 수 있으며 "10duk"은 개인정보취급방침에 따라 해당
            회원의 회원 등록 말소를 위한 절차를 밟습니다.
          </li>
          <li className="mb-2">
            회원이 다음 각 호의 사유에 해당하는 경우, "10duk"은 회원의
            회원자격을 적절한 방법으로 제한 및 정지 또는 상실시킬 수 있습니다.
            <ul className="my-2">
              <li className="mb-2">
                가. 공지사항 또는 각 게시판 공지사항에 위반하는 활동을 하는 경우
              </li>
              <li className="mb-2">
                나. 다른 사람의 "서비스" 이용을 방해하거나 그 정보를 도용하는 등
                "서비스"상 위협에 해당하는 행동의 경우
              </li>
              <li className="mb-2">
                다. 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하는
                경우
              </li>
            </ul>
          </li>
          <li className="mb-2">
            "10duk"은 회원의 회원자격을 상실시키기로 결정한 경우에는 회원등록을
            말소합니다.
          </li>
          <li className="mb-2">
            회원 탈퇴가 이루어진 경우 게시판에 등록된 게시글 및 댓글은 삭제되지
            않으며, 회원이 작성한 게시물 등의 삭제를 원하는 경우 직접 삭제 후
            탈퇴해야 합니다.
          </li>
          <li className="mb-2">
            작성한 게시글 및 댓글에 남아있는 개인정보 침해 사유 내용은, 탈퇴
            이후라도 작성자 본인임을 확인할 수 있는 증명자료, 관련 사유, 첨부
            자료를 개인정보취급방침 상 개인정보보호책임팀에 요청하면 심의 후
            개별적으로 삭제될 수 있습니다.
          </li>
        </ul>

        <div className="text-xl font-semibold mt-6 mb-2">
          제 6조 서비스의 제공 및 변경
        </div>
        <ul className=" list-decimal pl-5 mt-2">
          <li>
            "10duk"은 회원과 서비스 이용 계약이 성립한 때로부터 다음 서비스의
            제공을 개시합니다.
          </li>
          <ul className="mt-2">
            <li className="mb-2">가. 사이트내의 컨텐츠 이용 서비스</li>
            <li className="mb-2">
              나. 기타 "10duk"에서 자체 개발하거나 다른 회사와의 협력계약 등을
              통해 회원들에게 제공할 일체의 서비스
            </li>
          </ul>
          <li className="mt-2">
            "10duk"은 그 변경될 서비스의 내용 및 제공일자를 제8조에서 정한
            방법으로 회원에게 통지할 수 있습니다.
          </li>
        </ul>

        <div className="text-xl font-semibold mt-6 mb-2">
          제 7조 서비스의 중단
        </div>
        <ul className=" list-decimal ml-5 my-2">
          <li className="mb-2">
            "10duk"은 컴퓨터 등 정보통신설비의 보수점검·교체 및 고장, 통신의
            두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할
            수 있고, 새로운 서비스로의 교체나 기타 "10duk"이 적절하다고 판단하는
            사유에 기하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다.
          </li>
          <li className="mb-2">
            제1항에 의한 서비스 중단의 경우에는 "10duk"은 제8조 제2항에서 정한
            방법으로 회원에게 통지합니다. 다만, "10duk"에서 통제할 수 없는
            사유로 인한 서비스의 중단(시스템 관리자의 고의, 과실이 없는 디스크
            장애, 시스템 다운 등)으로 인하여 사전 통지가 불가능한 경우에는
            그러하지 아니합니다.
          </li>
        </ul>

        <div className="text-xl font-semibold mt-6 mb-2">
          제8조 회원에 대한 통지
        </div>
        <ul className=" list-decimal ml-5 my-2">
          <li className="mb-2">
            "10duk"은 특정 회원에게 대한 통지를 하는 경우, “10duk”의 ‘알림이’
            기능 등을 통해 해당 내용을 통지합니다.
          </li>
          <li className="mb-2">
            "10duk"은 불특정 다수 회원에 대한 통지를 하는 경우, "10duk"의
            공지사항 게시판 및 관련 게시판에 게시함으로써 개별 통지에 갈음할 수
            있습니다.
          </li>
        </ul>

        <div className="text-xl font-semibold mt-6 mb-2">
          제 9조 회원의 개인정보 보호
        </div>
        <ul className=" list-decimal ml-5 my-2">
          <li className="mb-2">
            "10duk"은 관련법령이 정하는 바에 따라서 회원 등록정보를 포함한
            회원의 개인정보를 보호하기 위하여 노력합니다.
          </li>
          <li className="mb-2">
            회원의 개인정보보호에 관해서는 관련법령 및 "10duk”에서 정하는
            "개인정보취급방침"에 정한 바에 의합니다.
          </li>
        </ul>

        <div className="text-xl font-semibold mt-6 mb-2">
          제 10조 10duk의 의무
        </div>
        <ul className=" list-decimal pl-5 mt-2">
          <li>
            10duk은 법령과 이 약관이 정하는 사항을 준수하며, 다음 각 호의 사유가
            발생한 경우를 제외하고 지속적이고 안정적으로 서비스를 제공하기 위해
            최선을 다합니다.
          </li>
          <ul className="mt-2">
            <li className="mb-2">
              가. 서비스용 설비의 보수, 정기점검 또는 공사로 인한 부득이한 경우
            </li>
            <li className="mb-2">
              나. 설비 장애 또는 이용 폭주, 해킹 등으로 인하여 서비스 이용에
              지장이 있는 경우
            </li>
            <li className="mb-2">
              다. 전시, 사변, 천재지변 또는 이에 준 하는 국가비상사태가
              발생하거나 발생할 우려가 있는 경우
            </li>
          </ul>
          <li className="mt-2">
            10duk은 회원이 원하지 않는 영리목적의 광고성 전자우편을 발송하지
            않습니다.
          </li>
        </ul>

        <div className="text-xl font-semibold mt-6 mb-2">
          제 11조 회원의 의무
        </div>
        <p>이용자는 다음 행위를 하여서는 안 됩니다.</p>
        <ul className=" list-decimal ml-5 my-2">
          <li className="mb-2">
            본 약관을 포함하여 회사의 공지사항 이용지침 위반하는 행위
          </li>
          <li className="mb-2">
            회사 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
          </li>
          <li className="mb-2">
            외설 또는 폭력적인 메시지·화상·음성 기타 공서양속에 반하는 정보를
            홈페이지에 공개 또는 게시하는 행위
          </li>
          <li className="mb-2">해킹 또는 컴퓨터 바이러스를 유포하는 행위</li>
          <li className="mb-2">
            "10duk"의 직원이나 “10duk” 서비스 관리자를 가장하거나 사칭하여 또는
            타인의 명의를 모용하여 글을 게시하거나 메일을 발송하는 행위
          </li>
          <li className="mb-2">
            컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해,
            파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드,
            파일, 프로그램을 포함하고 있는 자료를 게시하거나 전자우편으로
            발송하는 행위
          </li>
          <li className="mb-2">
            다른 회원에 대한 개인정보를 그 동의 없이 수집,저장,공개하는 행위
          </li>
          <li className="mb-2">서버나 회선에 무리를 줄 수 있는 행위</li>
        </ul>
        <p>
          위의 각 호에 해당하는 행위를 한 회원이 있을 경우 "10duk"은 회원의
          회원자격을 적절한 방법으로 제한 및 정지, 상실시킬 수 있으며, 회원은 그
          귀책사유로 인하여 "10duk"이나 다른 회원이 입은 손해를 배상할 책임이
          있습니다.
        </p>

        <div className="text-xl font-semibold mt-6 mb-2">
          제 12조 저작권의 귀속
        </div>
        <ul className=" list-decimal pl-5 mt-2">
          <li>
            "10duk"이 작성한 저작물에 대한 저작권 기타 지적재산권은 "10duk"에
            귀속합니다.
          </li>
          <li className="mt-2">
            회원이 서비스 내에 게시한 게시물의 저작권은 게시한 회원에게
            귀속됩니다. 단, “10duk”은 서비스의 운영, 전시, 전송, 배포, 홍보의
            목적으로 회원의 별도의 허락 없이 무상으로 저작권법에 규정하는 공정한
            관행에 합치되게 합리적인 범위 내에서 다음과 같이 회원이 등록한
            게시물을 사용할 수 있습니다.
          </li>
          <ul className="mt-2">
            <li className="mb-2">
              가. 서비스 내에서 회원 게시물의 복제, 수정, 개조, 전시, 전송, 배포
              및 저작물성을 해치지 않는 범위 내에서의 편집 저작물 작성
            </li>
            <li className="mb-2">
              나. 게시물 검색 서비스 등 향상된 서비스 제공을 위하여 관련
              제휴사에게 필요한 자료(게시물 제목 및 내용, 게시일, 조회수 등)를
              복제, 전송하는 것. 단, 이 경우 "10duk"은 별도의 동의 없이 회원의
              개인정보를 제공하지 않습니다.
            </li>
            <li className="mb-2">
              다. 미디어, 통신사 등 서비스 제휴사에게 회원의 게시물 내용을 제공,
              전시 혹은 홍보하게 하는 것. 단, 이 경우 "10duk"은 별도의 동의 없이
              회원의 개인정보를 제공하지 않습니다.
            </li>
          </ul>
          <li className="mt-2">
            "10duk"은 전항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우,
            이메일 또는 기타 방식으로 회원의 사전 동의를 얻어야 합니다.
          </li>
          <li className="mt-2">
            작성한 게시물로 인해 발생되는 문제에 대해서는 해당 게시물을 게시한
            게시자에게 책임이 있으며, 타인의 권리를 침해한 게시물은 침해 당사자
            또는 권한 대리인의 요청에 의해 삭제될 수 있습니다.
          </li>
          <li className="mt-2">
            10duk은 회사의 합병, 영업양도, 회사가 운영하는 사이트 간의 통합,
            기타 서비스 개편 등의 사유로 게시물의 게시 위치를 변경할 수
            있습니다.
          </li>
          <li className="mt-2">
            작성한 게시물로 인해 타인 및 저작물의 저작권을 침해하는 경우 이에
            대한 민.형사상의 책임은 글 게시자에게 있습니다. 만일 이를 이유로
            "10duk"이 타인에게 손해배상청구 등 이의 제기를 받은 경우 해당
            게시자는 그로 인해 발생되는 모든 손해를 부담해야 합니다.
          </li>
        </ul>

        <div className="text-xl font-semibold mt-6 mb-2">
          제 13조 게시물 관련 사항
        </div>
        <ul className=" list-decimal pl-5 mt-2">
          <li>
            10duk은 게시물 및 내용물에 관한 세부 이용지침(공지사항)을 별도로
            정하여 운영할 수 있으며 회원은 그 지침에 따라 게시물 등을 등록하여야
            합니다.
          </li>
          <li className="mt-2">
            회원의 공개게시물의 내용이 다음 각 호에 해당하는 경우 회사는 해당
            공개게시물에 대한 접근을 임시적으로 차단하는 조치 내지 해당 게시물을
            이동, 삭제할 수 있고, 필요하다고 판단되는 경우 해당 회원의 회원
            자격을 제한, 정지 또는 상실시킬 수 있습니다.
          </li>
          <ul className="mt-2">
            <li className="mb-2">가. 이용지침(공지사항)에 위반하는 내용</li>
            <li className="mb-2">
              나. 다른 회원 또는 제3자를 비방하거나 명예를 손상시키는 내용
            </li>
            <li className="mb-2">다. 제3자의 저작권 등 권리를 침해하는 내용</li>
            <li className="mb-2">라. 범죄행위와 관련이 있다고 판단되는 내용</li>
            <li className="mb-2">
              마. 기타 관계법령 및 공서양속에 위반되는 내용
            </li>
          </ul>
          <li className="mt-2">
            회사는 회원의 게시물 등에 대하여 다른 이용자 혹은 제3자의 법률상
            권리 침해를 근거로 게시 중단 요청을 받은 경우 게시물을 임시로 게시
            중단할 수 있으며 게시 중단 요청자와 게시물 등록 회원간의 합의 또는
            법적 조치의 결과(예: 법원의 판결, 결정, 검찰의 처분) 등이 회사에
            접수되면 그에 따릅니다.
          </li>
        </ul>

        <div className="text-xl font-semibold mt-6 mb-2">
          제14조 정보의 제공 및 광고 게재
        </div>
        <ul className=" list-decimal pl-5 mt-2">
          <li>
            ”10duk”은 서비스 이용에 필요가 있다고 인정되는 각종 정보 또는 광고를
            서비스 화면에 게재할 수 있습니다. 회원은 회원이 등록한 게시물의
            내용을 활용한 광고게재 및 기타 서비스상 노출되는 광고게재에 대해
            동의합니다.
          </li>
          <li className="mt-2">
            회원은 서비스상에 게재된 광고를 이용하거나 서비스를 통한 광고주의
            판촉활동 및 각종 제휴 이벤트에 참여할 수 있습니다.
          </li>
          <li className="mt-2">
            ”10duk”은 서비스상에 게재되어 있거나 서비스를 통한 광고주의
            판촉활동에 회원이 참여하거나 그와 관련된 거래를 함으로써 발생하는
            손해에 대해 책임을 지지 않습니다.
          </li>
        </ul>

        <div className="text-xl font-semibold mt-6 mb-2">제15조 손해배상</div>
        <p>
          회원이 서비스를 이용함에 있어 행한 불법적 행위나 본 약관의 규정을
          위반함으로 인하여 발생한 법적 책임은 모두 본인에게 있습니다. 또한
          그러한 행위로 인하여 “10duk” 또는 다른 회원에게 손해가 발생하거나
          제3자로부터 회사 또는 회원을 대상으로 손해배상청구 소송을 비롯한 각종
          민형사상의 법적 조치 등의 이의제기를 받는 경우, 해당 회원은 그
          귀책사유로 인하여 손해를 배상할 책임이 있습니다.
        </p>

        <div className="text-xl font-semibold mt-6 mb-2">제16조 분쟁해결</div>
        <p>
          회사와 회원 간에 발생한 서비스 이용에 관한 분쟁에 대하여는 대한민국
          법을 적용하며, 본 분쟁으로 인한 소는 민사소송법상의 관할을 가지는
          대한민국의 법원에 제기합니다.
        </p>

        <p className="my-4">
          (시행일) 본 약관은 2023년 10월 1일부터 적용됩니다.
        </p>
      </div>
    </>
  );
}

export default TermsOfUsePage;

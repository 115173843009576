import React, { useCallback } from "react";
import { useState } from "react";
import { localLogin, localSignup } from "../../apis/user";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../constants/types";
import {
  updateLoginStatus,
  updateAccessToken,
  updateUserId,
  updateAdminStatus,
  updateAdminAdminStatus,
} from "../../store/authReducer";

function LocalLogin() {
  const [loginId, setLoginId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [signLoginId, setSignLoginId] = useState<string>("");
  const [signPassword, setSignPassword] = useState<string>("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const updateUserStatus = useCallback(
    (res: any) => {
      const accessToken = res.accessToken;
      const userId = res.userId;
      const isAdmin = res.isAdmin;
      const isAdminAdmin = res.isAdminAdmin;
      dispatch(updateLoginStatus(true));
      dispatch(updateAccessToken(accessToken));
      dispatch(updateUserId(userId));
      dispatch(updateAdminStatus(isAdmin));
      dispatch(updateAdminAdminStatus(isAdminAdmin));
    },
    [dispatch]
  );

  const handleSubmitLogin = (e: React.FormEvent) => {
    e.preventDefault();
    localLogin(loginId, password)
      .then((res) => {
        updateUserStatus(res);
        alert("로그인 성공");
        navigate("/");
      })
      .catch((err) => {});
  };

  const handleSubmitSignup = (e: React.FormEvent) => {
    e.preventDefault();
    localSignup(signLoginId, signPassword)
      .then((res) => {})
      .catch((err) => {});
  };

  return (
    <div>
      LocalLogin
      <form action="" className="" onSubmit={handleSubmitLogin}>
        <input
          id="id"
          type="text"
          placeholder="아이디"
          value={loginId}
          onChange={(e) => setLoginId(e.target.value)}
          className="border p-1"
        />
        <input
          id="password"
          type="password"
          placeholder="비밀번호"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="border p-1"
        />
        <button
          type="submit"
          className="border p-1 bg-red-300"
          onClick={handleSubmitLogin}
        >
          로그인
        </button>
      </form>
      {/* <br />
      <br />
      <br />
      <br />
      <form action="" className="" onSubmit={handleSubmitSignup}>
        <input
          id="signid"
          type="text"
          placeholder="아이디"
          value={signLoginId}
          onChange={(e) => setSignLoginId(e.target.value)}
          className="border p-1"
        />
        <input
          id="signpassword"
          type="password"
          placeholder="비밀번호"
          value={signPassword}
          onChange={(e) => setSignPassword(e.target.value)}
          className="border p-1"
        />
        <button
          type="submit"
          className="border p-1 bg-red-300"
          onClick={handleSubmitSignup}
        >
          회원가입
        </button>
      </form> */}
    </div>
  );
}

export default LocalLogin;

import { SERVER_URL } from "../constants/url";
import { axiosFileInstance, axiosInstance } from "./instance";

//네이버 로그인 리다이렉트
export const redirectNaver = () => {
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const REDIRECT_URI = process.env.REACT_APP_NAVER_REDIRECT_URI;
  // const REDIRECT_URI = "https://10duk.com/login/auth2/naver";
  // const REDIRECT_URI = `http://localhost:3000/login/auth2/naver`;
  const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}`;
  window.location.href = NAVER_AUTH_URL;
};

// 카카오 로그인 리다이렉트
export const redirectKakao = () => {
  const CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
  const REDIRECT_URI = process.env.REACT_APP_KAKAO_REDIRECT_URI;
  // const REDIRECT_URI = "https://10duk.com/login/auth2/kakao";
  // const REDIRECT_URI = `http://localhost:3000/login/auth2/kakao`;
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}`;
  window.location.href = KAKAO_AUTH_URL;
};

// 구글 로그인 리다이렉트
export const redirectGoogle = () => {
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const REDIRECT_URI = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
  // const REDIRECT_URI = "https://10duk.com/login/auth2/google";
  // const REDIRECT_URI = `http://localhost:3000/login/auth2/google`;
  const GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email`;
  window.location.href = GOOGLE_AUTH_URL;
};

// 네이버 로그인
export const loginNaver = async (code: string) => {
  const res = await axiosInstance.get<any>(`auth/naver/login?code=${code}`);
  return res.data;
};

// 카카오 로그인
export const loginKakao = async (code: string) => {
  const res = await axiosInstance.get<any>(`auth/kakao/login?code=${code}`);
  return res.data;
};

// 구글 로그인
export const loginGoogle = async (code: string) => {
  const res = await axiosInstance.get<any>(`auth/google/login?code=${code}`);
  return res.data;
};

// 로컬 로그인
export const localLogin = async (loginId: string, password: string) => {
  const res = await axiosInstance.post<any>("auth/local/login", {
    loginId,
    password,
  });
  return res.data;
};

// 로컬 회원가입
export const localSignup = async (loginId: string, password: string) => {
  const res = await axiosInstance.post<any>("auth/local/signup", {
    loginId,
    password,
  });
  return res.data;
};

// 로그아웃
export const logout = async () => {
  await axiosInstance.post<any>(`auth/logout`);
};

// 유저 프로필 조회
export const getUserInfo = async () => {
  const res = await axiosInstance.get<any>(`/user/profile`);
  return res.data;
};

// 유저 작성 글 목록 조회
export const getUserPosts = async (pageNumber: number) => {
  const res = await axiosInstance.get<any>(`/user/profile/post/${pageNumber}`);
  return res.data;
};

// 유저 작성 댓글 목록 조회
export const getUserComments = async (pageNumber: number) => {
  const res = await axiosInstance.get<any>(
    `/user/profile/comment/${pageNumber}`
  );
  return res.data;
};

// 유저 북마크 목록 조회
export const getUserBookmarks = async (pageNumber: number) => {
  const res = await axiosInstance.get<any>(
    `/user/profile/bookmark/${pageNumber}`
  );
  return res.data;
};

// 게시판 즐겨찾기 목록 조회
export const getBoardBookmarks = async () => {
  const res = await axiosInstance.get<any>(`/boardName/bookmark`);
  return res.data;
};

// 게시판 북마크 유무 조회
export const getBoardBookmark = async (boardNumber: number) => {
  const res = await axiosInstance.get<any>(
    `/user/bookmark/board/${boardNumber}`
  );
  return res.data;
};

// 글 상세 북마크 유무 조회
export const getPostBookmark = async (postId: string) => {
  const res = await axiosInstance.get<any>(`/user/bookmark/post/${postId}`);
  return res.data;
};

// 회원 탈퇴
export const deleteUser = async () => {
  const res = await axiosInstance.delete<any>(`/auth/delete`);
  return res.data;
};

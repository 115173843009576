import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AllBoardName from "../Board/AllBoardName";
import BookmarkedBoardName from "../Board/BookmarkedBoardName";
import { useAppSelector } from "../../constants/types";
import Notification from "../Notification/Notification";

function BoardNavBar() {
  const [isBoardNameOpen, setIsBoardNameOpen] = useState<boolean>(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [isBookmarkedBoardNameOpen, setIsBookmarkedBoardNameOpen] =
    useState<boolean>(false);

  const boardNameRef = useRef<HTMLDivElement>(null);
  const hamburgerRef = useRef<HTMLDivElement>(null);
  const notificationRef = useRef<HTMLDivElement>(null);
  const notificationBellRef = useRef<HTMLDivElement>(null);
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const navigate = useNavigate();
  const unreadNotifications = useAppSelector(
    (state) => state.notification.unreadNotifications
  );
  const hasUnreadNotification = unreadNotifications.length > 0;

  const goToTotalPostPage = () => {
    navigate("/post/totalpost");
  };
  const goToHotDukPage = () => {
    navigate("/post/hotduk");
  };

  const goToRankDuckPage = () => {
    navigate("/post/rankduk");
  };

  const toggleboardNameOpen = () => {
    setIsBoardNameOpen(!isBoardNameOpen);
    // setIsBookmarkedBoardNameOpen(false);
  };

  const toggleNotificationOpen = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  const toggleBookmarkedBoardNameOpen = () => {
    setIsBookmarkedBoardNameOpen(!isBookmarkedBoardNameOpen);
    setIsBoardNameOpen(false);
  };

  const handleClickOutside = (e: any) => {
    if (
      boardNameRef.current &&
      !boardNameRef.current.contains(e.target) &&
      hamburgerRef.current &&
      !hamburgerRef.current.contains(e.target)
    ) {
      setIsBoardNameOpen(false);
      // setIsBookmarkedBoardNameOpen(false);
    }
    if (
      notificationRef.current &&
      !notificationRef.current.contains(e.target) &&
      notificationBellRef.current &&
      !notificationBellRef.current.contains(e.target)
    ) {
      setIsNotificationOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <>
      <div className=" w-full border-b border-header_line whitespace-nowrap">
        <div className="w-2/3 flex py-3  mx-auto items-center justify-between">
          <div className="flex">
            <div
              className="flex text-textcolor text-sm cursor-pointer pl-3 pr-5 hover:opacity-50"
              onClick={toggleboardNameOpen}
              ref={hamburgerRef}
            >
              게시판 목록
              <div className="flex justify-center items-center ml-2">
                <img
                  src="/assets/icon/arrow_down.svg"
                  alt="arrow_down"
                  className="w-2 h-2"
                />
              </div>
            </div>
            <div className="flex items-center">
              <div
                className="flex items-center justify-center text-textcolor text-sm ml-6 cursor-pointer hover:opacity-50"
                onClick={goToTotalPostPage}
              >
                전체 게시판
              </div>

              <div
                className="flex items-center justify-center text-hotduk text-sm font-semibold ml-6 cursor-pointer hover:opacity-50"
                onClick={goToHotDukPage}
              >
                오늘의 HOT덕
                <img
                  src="/assets/icon/hotduk.svg"
                  alt="hotduk"
                  className="w-3 ml-[6px]"
                />
              </div>
              <div
                className="flex justify-center text-rankduk text-sm font-semibold ml-6 cursor-pointer hover:opacity-50"
                onClick={goToRankDuckPage}
              >
                오늘의 RANK덕
                <img
                  src="/assets/icon/rankduk.svg"
                  alt="rankduk"
                  className="w-3 ml-[6px]"
                />
              </div>
            </div>
          </div>
          {isLogin && (
            <div
              className="flex justify-end text-textcolor text-sm font-semibold cursor-pointer hover:opacity-50 mr-4"
              onClick={toggleNotificationOpen}
              ref={notificationBellRef}
            >
              {hasUnreadNotification ? (
                <img
                  src="/assets/icon/notification_red.svg"
                  className="w-[22px]"
                />
              ) : (
                <img
                  src="/assets/icon/notification_white.svg"
                  className="w-[22px]"
                />
              )}
            </div>
          )}
        </div>
        <div className=" w-2/3 mx-auto flex relative ">
          {isBoardNameOpen && (
            <div
              className="absolute top-full bg-white shadow-md z-50"
              ref={boardNameRef}
            >
              <AllBoardName toggleBoardName={toggleboardNameOpen} />
            </div>
          )}
        </div>
        <div className=" w-2/3 mx-auto flex relative ">
          {isNotificationOpen && (
            <div
              className="absolute right-0 top-full bg-white shadow-md z-50 w-48"
              ref={notificationRef}
            >
              <Notification toggleNotification={toggleNotificationOpen} />
            </div>
          )}
        </div>

        <div className=" flex flex-row-reverse relative ">
          {isLogin ? (
            <>
              {isBookmarkedBoardNameOpen && (
                <div className="absolute right-16 top-full bg-white shadow-md p-2 z-50">
                  <BookmarkedBoardName />
                </div>
              )}
            </>
          ) : (
            <>
              {isBookmarkedBoardNameOpen && (
                <div className="absolute right-6 top-full bg-white shadow-md p-2 z-50">
                  <div className="flex justify-center text-navy text-xs font-semibold">
                    로그인 후 이용해주세요.
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default BoardNavBar;

import MainContent from "../../components/Main/MainContent";
import MainVoteBoard from "../../components/Main/MainVoteBoard";

function MainPage() {
  return (
    <div className="w-full">
      <MainVoteBoard />
      <div className="mt-6">
        <MainContent />
      </div>
    </div>
  );
}

export default MainPage;

import TotalPost from "../../components/Posts/TotalPost";

function TotalPostPage() {
  return (
    <>
      <div className="w-full">
        <div className="my-5">
          <div className=" text-textcolor text-2xl font-bold">전체게시판</div>
        </div>
        <div>
          <TotalPost />
        </div>
      </div>
    </>
  );
}

export default TotalPostPage;

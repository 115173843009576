import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getTotalPosts } from '../../apis/post';
import Pagination from '../Common/Pagination';
import {
  TotalPostData,
  useAppSelector,
  IntegratedAnnouncements,
  Announcements,
} from '../../constants/types';

function TotalPost() {
  const isLogin = useAppSelector((state) => state.auth.isLogin);

  const { pageNumber } = useParams<{ pageNumber: string }>();
  const [rankerPostList, setRankerPostList] = useState<TotalPostData[]>([]);
  const [normalPostList, setNormalPostList] = useState<TotalPostData[]>([]);
  const [integratedAnnouncements, setIntegratedAnnouncements] = useState<
    IntegratedAnnouncements[]
  >([]); //전체 공지사항
  const [announcements, setAnnouncements] = useState<Announcements[]>([]); //게시판별 공지사항
  const [currentPage, setCurrentPage] = useState<number>(
    pageNumber ? parseInt(pageNumber, 10) : 1
  );
  const [totalPage, setTotalPage] = useState<number>(1);
  const navigate = useNavigate();
  const handlePostClick = (boardNumber: number, postId: string) => {
    navigate(`/post/${boardNumber}/${postId}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getTotalPosts(currentPage).then((res) => {
      setAnnouncements(res.announcements);
      setIntegratedAnnouncements(res.integratedAnnouncements);
      setRankerPostList(res.posts.rankerPosts);
      setNormalPostList(res.posts.normalPosts);
      setTotalPage(res.totalPageNumber);
    });
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(pageNumber ? parseInt(pageNumber, 10) : 1);
  }, [pageNumber]);

  const handleAnnouoncementClick = (postId: string) => {
    navigate(`/announcement/${postId}`);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.history.pushState(null, '', `/post/totalpost/${page}`);
    window.location.reload();
  };

  const renderNormalPosts = (posts: TotalPostData[]) => {
    return (
      <tbody>
        {posts.map((post) => (
          <tr
            key={post.id}
            className='cursor-pointer text-sm items-center border-b border-[#FBF3FF] hover:bg-[#F8F8F8]'
            onClick={() => handlePostClick(post.boardNumber, post.id)}
          >
            <td className='py-[10px] pl-4 flex items-center font-normal'>
              <div className=' flex-shrink-0'>
                {/* 여기서 hot populartiy 수정  */}
                {post.viewCount < 150 ? (
                  <div className='pl-24'></div>
                ) : (
                  <img
                    src='/assets/icon/hot_label.svg'
                    alt='hot_label'
                    className='mr-4 w-20 py-1'
                  />
                )}
              </div>
              <div className='flex text-sm font-normal items-start opacity-50 pr-2 whitespace-nowrap'>
                {post.boardName}
              </div>
              {post.title}
              {post.mainImage && (
                <img
                  src='/assets/icon/10duk_photo.svg'
                  alt='image'
                  className='ml-[6px] w-[14px]'
                />
              )}
              <div className='flex items-center'>
                {post.commentCount !== 0 && (
                  <div className=' text-hotduk font-bold px-2'>
                    [{post.commentCount}]
                  </div>
                )}
              </div>
            </td>
            <td className='py-2 px-1 items-center justify-center'>
              {post.ranker ? (
                <div className='flex justify-center items-center'>
                  <img
                    src='/assets/icon/ranker_crown.svg'
                    alt='ranker_crown'
                    className='w-5 pr-1'
                  />
                  <p className='flex font-normal'>익명</p>
                </div>
              ) : (
                <div className='pl-5 text-center font-normal'>익명</div>
              )}
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {post.viewCount}
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {post.voteUpCount}
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {Math.floor((Date.now() - Date.parse(post.createdAt)) / 3600000) <
              24 ? (
                <div>
                  {`${new Date(new Date(post.createdAt))
                    .getHours()
                    .toString()
                    .padStart(2, '0')}:${new Date(new Date(post.createdAt))
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')}`}{' '}
                </div>
              ) : (
                <>
                  {Math.floor(
                    (Date.now() - Date.parse(post.createdAt)) / 86400000
                  ) > 364 ? (
                    <div>
                      {`${new Date(new Date(post.createdAt)).getFullYear()}.${(
                        new Date(new Date(post.createdAt)).getMonth() + 1
                      )
                        .toString()
                        .padStart(2, '0')}.${new Date(new Date(post.createdAt))
                        .getDate()
                        .toString()
                        .padStart(2, '0')}`}{' '}
                    </div>
                  ) : (
                    <div>
                      {`${(new Date(new Date(post.createdAt)).getMonth() + 1)
                        .toString()
                        .padStart(2, '0')}.${new Date(new Date(post.createdAt))
                        .getDate()
                        .toString()
                        .padStart(2, '0')}`}{' '}
                    </div>
                  )}
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  const renderRankerPosts = (posts: TotalPostData[]) => {
    return (
      <tbody>
        {posts.map((post) => (
          <tr
            key={post.id}
            className='cursor-pointer bg-[#FFF8EA] text-sm items-center border-b border-white hover:bg-[#FDC500] hover:bg-opacity-20'
            onClick={() => handlePostClick(post.boardNumber, post.id)}
          >
            <td className='py-[10px] pl-4 flex items-center'>
              <div className=' flex-shrink-0'>
                {/* 여기서 hot populartiy 수정  */}
                {post.viewCount < 150 ? (
                  <img
                    src='/assets/icon/rank_label.svg'
                    alt='rank_label'
                    className='mr-2 w-20'
                  />
                ) : (
                  <img
                    src='/assets/icon/hot_label.svg'
                    alt='hot_label'
                    className='mr-2 w-20 py-1'
                  />
                )}
              </div>
              <div className=' font-semibold px-2 flex'>
                <div className='flex text-sm font-normal items-start opacity-50 pr-2 whitespace-nowrap'>
                  {post.boardName}
                </div>
                {post.title}
                {post.mainImage && (
                  <img
                    src='/assets/icon/10duk_photo.svg'
                    alt='image'
                    className='ml-[6px] w-[14px]'
                  />
                )}
              </div>
              <div className='flex items-center'>
                {post.commentCount !== 0 && (
                  <div className=' text-hotduk font-bold'>
                    [{post.commentCount}]
                  </div>
                )}
              </div>
            </td>
            <td className='py-2 px-1 items-center justify-center'>
              <div className='flex justify-center items-center'>
                <img
                  src='/assets/icon/ranker_crown.svg'
                  alt='ranker_crown'
                  className='w-5 pr-1'
                />
                <p className='flex font-normal'>익명</p>
              </div>
            </td>
            <td className='py-2 px-1 text-center items-center font-normal'>
              {post.viewCount}
            </td>
            <td className='py-2 px-1 text-center items-center font-normal'>
              {post.voteUpCount}
            </td>
            <td className='py-2 px-1 text-center items-center font-normal'>
              {Math.floor((Date.now() - Date.parse(post.createdAt)) / 3600000) <
              24 ? (
                <div>
                  {`${new Date(new Date(post.createdAt))
                    .getHours()
                    .toString()
                    .padStart(2, '0')}:${new Date(new Date(post.createdAt))
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')}`}{' '}
                </div>
              ) : (
                <>
                  {Math.floor(
                    (Date.now() - Date.parse(post.createdAt)) / 86400000
                  ) > 364 ? (
                    <div>
                      {`${new Date(new Date(post.createdAt)).getFullYear()}.${(
                        new Date(new Date(post.createdAt)).getMonth() + 1
                      )
                        .toString()
                        .padStart(2, '0')}.${new Date(new Date(post.createdAt))
                        .getDate()
                        .toString()
                        .padStart(2, '0')}`}{' '}
                    </div>
                  ) : (
                    <div>
                      {`${(new Date(new Date(post.createdAt)).getMonth() + 1)
                        .toString()
                        .padStart(2, '0')}.${new Date(new Date(post.createdAt))
                        .getDate()
                        .toString()
                        .padStart(2, '0')}`}{' '}
                    </div>
                  )}
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  const renderAnnouncementPost = (posts: Announcements[]) => {
    const post = posts[0];
    return (
      <tbody>
        <tr
          key={post.id}
          className='cursor-pointer text-sm items-center border-b border-[#FBF3FF] hover:bg-[#F8F8F8]'
          onClick={() => handleAnnouoncementClick(post.id)}
        >
          <td className='py-[10px] pl-4 flex items-center font-normal'>
            <div className=' flex-shrink-0'>
              <img
                src='/assets/icon/notice_label.svg'
                alt='notice_label'
                className='mr-2 w-20 py-1'
              />
            </div>
            <p className=' font-semibold px-2'>{post.title}</p>
          </td>
          <td className='py-2 px-1 items-center justify-center'>
            <div className='pl-5 text-center font-normal'>운영자덕</div>
          </td>
          <td className='py-2 px-1 text-center font-normal'>
            {post.viewCount}
          </td>
          <td className='py-2 px-1 text-center font-normal'>0</td>
          <td className='py-2 px-1 text-center font-normal'>
            {Math.floor((Date.now() - Date.parse(post.createdAt)) / 3600000) <
            24 ? (
              <div>
                {`${new Date(new Date(post.createdAt))
                  .getHours()
                  .toString()
                  .padStart(2, '0')}:${new Date(new Date(post.createdAt))
                  .getMinutes()
                  .toString()
                  .padStart(2, '0')}`}{' '}
              </div>
            ) : (
              <>
                {Math.floor(
                  (Date.now() - Date.parse(post.createdAt)) / 86400000
                ) > 364 ? (
                  <div>
                    {`${new Date(new Date(post.createdAt)).getFullYear()}.${(
                      new Date(new Date(post.createdAt)).getMonth() + 1
                    )
                      .toString()
                      .padStart(2, '0')}.${new Date(new Date(post.createdAt))
                      .getDate()
                      .toString()
                      .padStart(2, '0')}`}{' '}
                  </div>
                ) : (
                  <div>
                    {`${(new Date(new Date(post.createdAt)).getMonth() + 1)
                      .toString()
                      .padStart(2, '0')}.${new Date(new Date(post.createdAt))
                      .getDate()
                      .toString()
                      .padStart(2, '0')}`}{' '}
                  </div>
                )}
              </>
            )}
          </td>
        </tr>
      </tbody>
    );
  };

  const renderIntegratedAnnouncementPost = (
    posts: IntegratedAnnouncements[]
  ) => {
    const slicedPosts = posts.slice(0, 2);
    return (
      <tbody>
        {slicedPosts.map((post, index) => (
          <tr
            key={post.id}
            className='cursor-pointer text-sm items-center border-b border-[#FBF3FF] hover:bg-[#F8F8F8]'
            onClick={() => handleAnnouoncementClick(post.id)}
          >
            <td className='py-[10px] pl-4 flex items-center font-normal'>
              <div className=' flex-shrink-0'>
                {index === 0 ? (
                  <img
                    src='/assets/icon/notice_label_patch.svg'
                    alt='notice_label_patch'
                    className='mr-2 w-20 py-1'
                  />
                ) : (
                  <img
                    src='/assets/icon/notice_label.svg'
                    alt='notice_label'
                    className='mr-2 w-20 py-1'
                  />
                )}
              </div>
              <p
                className={`font-semibold px-2 ${
                  index === 0 ? 'text-[#4373EF]' : 'text-[#B355CA]'
                }`}
              >
                {post.title}
              </p>
            </td>
            <td className='py-2 px-1 items-center justify-center'>
              <div className='text-center font-normal'>운영자덕</div>
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {post.viewCount}
            </td>
            <td className='py-2 px-1 text-center font-normal'>0</td>
            <td className='py-2 px-1 text-center font-normal'>
              {Math.floor((Date.now() - Date.parse(post.createdAt)) / 3600000) <
              24 ? (
                <div>
                  {`${new Date(new Date(post.createdAt))
                    .getHours()
                    .toString()
                    .padStart(2, '0')}:${new Date(new Date(post.createdAt))
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')}`}{' '}
                </div>
              ) : (
                <>
                  {Math.floor(
                    (Date.now() - Date.parse(post.createdAt)) / 86400000
                  ) > 364 ? (
                    <div>
                      {`${new Date(new Date(post.createdAt)).getFullYear()}.${(
                        new Date(new Date(post.createdAt)).getMonth() + 1
                      )
                        .toString()
                        .padStart(2, '0')}.${new Date(new Date(post.createdAt))
                        .getDate()
                        .toString()
                        .padStart(2, '0')}`}{' '}
                    </div>
                  ) : (
                    <div>
                      {`${(new Date(new Date(post.createdAt)).getMonth() + 1)
                        .toString()
                        .padStart(2, '0')}.${new Date(new Date(post.createdAt))
                        .getDate()
                        .toString()
                        .padStart(2, '0')}`}{' '}
                    </div>
                  )}
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div>
      <table className='w-full table-fixed mx-auto text-textcolor mb-6'>
        <thead>
          <tr className='bg-[#FAFAFA] text-sm font-semibold'>
            <th className='w-7/12 py-[10px]'>제목</th>
            <th className=' py-[10px]'> 글쓴이</th>
            <th className=' py-[10px]'> 조회수</th>
            <th className=' py-[10px]'> 추천</th>
            <th className=' py-[10px]'> 작성일</th>
          </tr>
        </thead>
        {integratedAnnouncements && integratedAnnouncements.length !== 0 ? (
          <>{renderIntegratedAnnouncementPost(integratedAnnouncements)}</>
        ) : (
          <></>
        )}
        {announcements && announcements.length !== 0 ? (
          <>{renderAnnouncementPost(announcements)}</>
        ) : (
          <></>
        )}
        {rankerPostList.length !== 0 ? (
          <>{renderRankerPosts(rankerPostList)}</>
        ) : (
          <></>
        )}
        {normalPostList.length !== 0 ? (
          <>{renderNormalPosts(normalPostList)}</>
        ) : (
          <></>
        )}
      </table>
      <Pagination
        currentPage={currentPage}
        totalPage={totalPage}
        onPageChange={(page) => handlePageChange(page)}
      />
    </div>
  );
}

export default TotalPost;

import LinkBtn from "../Common/LinkBtn";
import { useNavigate } from "react-router-dom";

function MobileMainVoteBoard() {
  const navigate = useNavigate();

  const goToVoteBoard = () => {
    navigate("/board/voteboard");
  };

  return (
    <div className="px-4 pt-4">
      <div
        className=" bg-vote_board flex justify-between items-center px-5 py-5 rounded"
        onClick={goToVoteBoard}
      >
        <p className="flex text-white text-xl font-bold">게시판 추가 투표</p>
        <LinkBtn
          text="바로가기"
          textStyle="text-white text-sm font-normal"
          padding="py-1 px-4"
          borderStyle="border border-opacity-60 border-white"
          btnFunc={goToVoteBoard}
        />
      </div>
    </div>
  );
}
export default MobileMainVoteBoard;

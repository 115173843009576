import React, { useEffect, useCallback } from "react";
import { loginNaver, loginKakao, loginGoogle } from "../../apis/user";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../constants/types";
import {
  updateLoginStatus,
  updateAccessToken,
  updateUserId,
  updateAdminStatus,
  updateAdminAdminStatus,
  updateRankerStatus,
  clearLoginRedirectUrl,
  updateBlockStatus,
} from "../../store/authReducer";
import { clearRedirectUrl } from "../../store/twitterReferrerSlice";

interface LoginRedirectPageProps {
  isNaver?: boolean;
  isKakao?: boolean;
  isGoogle?: boolean;
}

function LoginRedirectPage({
  isNaver,
  isKakao,
  isGoogle,
}: LoginRedirectPageProps) {
  const location = useLocation();
  const previousPath = location.pathname;
  const [searchParams, setSearchParams] = useSearchParams();
  let code = searchParams?.get("code");
  let state = searchParams?.get("state");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const redirectUrl = useAppSelector(
    (state) => state.twitterReferrer.redirectUrl
  );
  const loginRedirectUrl = useAppSelector(
    (state) => state.auth.loginRedirectUrl
  );
  const updateUserStatus = useCallback(
    (res: any) => {
      const accessToken = res.accessToken;
      const userId = res.userId;
      const isAdmin = res.isAdmin;
      const isAdminAdmin = res.isAdminAdmin;
      const isRanker = res.isRanker;
      const isBlocked = res.isBlocked;
      dispatch(updateLoginStatus(true));
      dispatch(updateAccessToken(accessToken));
      dispatch(updateUserId(userId));
      dispatch(updateAdminStatus(isAdmin));
      dispatch(updateAdminAdminStatus(isAdminAdmin));
      dispatch(updateRankerStatus(isRanker));
      dispatch(updateBlockStatus(isBlocked));
    },
    [dispatch]
  );

  useEffect(() => {
    const handleLoginSuccess = () => {
      if (redirectUrl) {
        navigate(redirectUrl);
        dispatch(clearRedirectUrl());
      } else if (loginRedirectUrl) {
        navigate(loginRedirectUrl);
        dispatch(clearLoginRedirectUrl());
      } else {
        navigate("/");
      }
    };

    // 네이버 로그인
    if (code && isNaver) {
      loginNaver(code)
        .then((res) => {
          updateUserStatus(res);
          alert("로그인 성공");
          handleLoginSuccess();
        })
        .catch((err) => {});
    }

    // 카카오 로그인
    if (code && isKakao) {
      loginKakao(code)
        .then((res) => {
          updateUserStatus(res);
          alert("로그인 성공");
          handleLoginSuccess();
        })
        .catch((err) => {});
    }

    // 구글 로그인
    if (code && isGoogle) {
      loginGoogle(code)
        .then((res) => {
          updateUserStatus(res);
          alert("로그인 성공");
          handleLoginSuccess();
        })
        .catch((err) => {});
    }
  }, []);

  return <div>로그인 중입니다...</div>;
}

export default LoginRedirectPage;
